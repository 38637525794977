.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ck-balloon-panel {
  z-index: 9999 !important;
}

.headerTitle {
  flex: 1 1 30%;
}

.headerTitle a {
  text-align: left;
  font-size: 2rem;
  display: flex;
  align-content: center;
  align-items: center;
  text-decoration: none;
  color: white;
}

.linkMenuHomeConected {
  flex: 1 1 30%;
}

.linkMenuHomeConected img {
  width: 270px;
  padding-top: 7px;
}

.avatarUserOnHeader {
  background-color: #564146 !important;
  margin-top: 24px;
  margin-left: 10px;
  cursor: pointer;
}

@media (max-width: 600px) {
  .headerTitle a {
    padding-left: 10px;
  }

  .linkMenuHomeConected img {
    width: 60%;
  }
}

@media (max-width: 1308px) {
  .menuIconeText {
    display: none;
  }

  .avatarUserOnHeader {
    margin-top: 17px;
  }
}

.menuIcone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-size: 11px;
}

.CartContentLigne {
  display: flex;
  align-content: center;
  align-items: center;
}

.CartContentLigne svg {
  margin-right: 20px;
  margin-top: -9px;
}

.iconePasswordVisible {
  transition: 1s all;
  cursor: pointer;
}

.iconePasswordVisible:hover {
  transform: scale(0.9);
  opacity: 0.9;
  box-shadow: 3px 7px 27px 10px rgba(253, 252, 252, 0.75) inset;
}

.iconePasswordVisible:active {
  transform: scale(0.75);
  opacity: 0.7;
}

/* datatable ajout  ==> debut */

.MuiTableCell-root div div svg[color="#564146"] {
  margin-left: 10px;
  position: relative;
  top: 5px;
}

.MuiTableCell-root div {
  line-height: 30px;
}

/* datatable ajout  ==> fin */

#root .MuiSnackbar-anchorOriginTopCenter {
  top: 50%;
  left: 50%;
  right: auto;
  transform: translate(-50%, -50%);
  z-index: 10000;
}

.listeDateUl {
  height: 0;
  width: 0;
  position: absolute;
  background-color: #ffffff;
  padding: 0px;
  box-sizing: border-box;
  left: 230px; /* MI 08.05.24, #5919606901, sinon ne voit pas les dates correctement dans les activity cards */
  z-index: 1000;
  margin-top: -20px;
}

.listeDateUl.hoverUl {
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);
  width: 650px;
  height: auto;
}
.listeDateUl.special  ul {
    position: absolute;
    background-color: #ffffff;
    left: -230px;
    max-width: calc(100% - 240px);
    padding: 0px;
    z-index: 10000;
  }
.listeDateUl.hoverUl  ul {
    padding: 10px;
  }

@media (max-width: 900px) {
  .listeDateUl.special  ul {
    position: absolute;
    background-color: #ffffff;
    left: -250px;
    max-width: 90vw;
  }
}

.listeDateUl > svg {
  position: absolute;
  top: 1px;
  left: -40px;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.listeDateUl li {
  display: none;
}

.listeDateUl.hoverUl li {
  display: inline;
  margin-right: 10px;
}

.conditionGeneralActivityCard {
  bottom: 2px;
  position: absolute;
}
.DialogBoxActivity {
  margin-bottom: 60px;
}

@media (max-width: 900px) {
  .conditionGeneralActivityCard {
     bottom: 50px;
     background-color: #ffffff;
  }
  .actionActivityCard{
    margin-bottom: 0px;
  }
  .DialogBoxActivity {
    margin-bottom: 160px;
  }
}